import React, { useState, useEffect } from 'react';
import * as XLSX from 'xlsx';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Box,
  TablePagination,
  Button,
  Skeleton,
  Typography,
} from '@mui/material';
import { saveAs } from 'file-saver';
import dayjs from 'dayjs';
import { useFirstLoad } from '../../hooks/useSkeloton';

interface DataTableProps {
  data: any[];
  uniqueId: string; // Add uniqueId as a prop
}

const DataTable: React.FC<DataTableProps> = ({ data, uniqueId }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { markLoaded } = useFirstLoad();

  useEffect(() => {
    if (data.length === 0) {
      markLoaded(); // Mark as loaded when no data is available
    }
  }, [data, markLoaded]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDownloadExcel = () => {
    if (data.length === 0) return;

    const columns = data.length > 0 ? Object.keys(data[0]).filter(col => col !== "__typename") : [];

    const excelData = data.map(row => {
      const formattedRow: Record<string, any> = {};
      columns.forEach(col => {
        const value = row[col];
        // Convert epoch timestamps to human-readable format
        if (typeof value === "number" && col.toLowerCase().includes("time")) {
          formattedRow[col] = dayjs.unix(value).format("YYYY-MM-DD HH:mm:ss");
        } else {
          formattedRow[col] = value !== null && value !== undefined ? value.toString() : "NA";
        }
      });
      return formattedRow;
    });

    // Create a new workbook and worksheet
    const worksheet = XLSX.utils.json_to_sheet(excelData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Data");

    // Write the Excel file and save it
    const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(blob, `${uniqueId}_data.xlsx`);
  };

  // Get all unique keys from the first object in the data array and filter out "__typename"
  const columns = data.length > 0 ? Object.keys(data[0]).filter(col => col !== '__typename') : [];

  return (
    <Paper>
      <Box display="flex" justifyContent="flex-end" p={2}>
        <Button variant="contained" color="primary" onClick={handleDownloadExcel}>
          Download Excel
        </Button>
      </Box>
      <TableContainer>
        {data.length === 0 ? (
          <Typography>No data available</Typography>
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((col) => (
                  <TableCell key={col}>{col}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, rowIndex) => (
                <TableRow key={rowIndex}>
                  {columns.map((col) => (
                    <TableCell key={col}>
                      {/* Render human-readable timestamps directly in the table */}
                      {typeof row[col] === 'number' && col.toLowerCase().includes('time')
                        ? dayjs.unix(row[col]).format('YYYY-MM-DD HH:mm:ss')
                        : row[col] !== null && row[col] !== undefined
                        ? row[col].toString()
                        : 'NA'}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </Paper>
  );
};

export default DataTable;
