// useSkeloton.tsx

import React, { createContext, useContext, useState } from "react";

const FirstLoadContext = createContext({
  isFirstLoad: true,
  markLoaded: () => {},
  resetFirstLoad: () => {},
});

export const FirstLoadProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const markLoaded = () => {
    setIsFirstLoad(false);
  };

  const resetFirstLoad = () => {
    setIsFirstLoad(true);
  };

  return (
    <FirstLoadContext.Provider value={{ isFirstLoad, markLoaded, resetFirstLoad }}>
      {children}
    </FirstLoadContext.Provider>
  );
};

export const useFirstLoad = () => {
  return useContext(FirstLoadContext);
};