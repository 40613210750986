// ...existing code...
import { 
  ApolloClient, 
  createHttpLink, 
  InMemoryCache, 
  ApolloProvider 
} from "@apollo/client";
import createUploadLink from "apollo-upload-client/createUploadLink.mjs";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { ApolloLink } from "@apollo/client";

// Create an upload link for file uploads
const uploadLink = createUploadLink({
  uri: process.env.REACT_APP_GRAPHQL_API_URL,
});

// Create an HTTP link for regular queries/mutations
const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_API_URL,
});

// Handle errors
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      console.error("[GraphQL error]:", { message, locations, path });
    });
  }
  if (networkError) {
    console.error("[Network error]:", networkError);
  }
});

// Set up authentication link to include the token in headers
const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("token");
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

// Combine links: error handling, auth, upload, and http
const combinedLink = ApolloLink.from([errorLink, authLink, uploadLink, httpLink]);

// Create Apollo Client
const apolloClient = new ApolloClient({
  link: combinedLink,
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          // ...custom field policies if needed
        },
      },
    },
  }),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "cache-and-network",
    },
    query: {
      fetchPolicy: "network-only",
    },
    mutate: {
      errorPolicy: "all",
    },
  },
});

// Export the Client and Provider
export { apolloClient, ApolloProvider };
// ...existing code...
