import { Card, CardContent, Typography, Grid, Skeleton } from '@mui/material';
import { VehicleFaultCode } from '../../types';
import { gql, useQuery } from '@apollo/client';
import { useState } from 'react';
import { useFirstLoad } from "../../hooks/useSkeloton";

const GET_DTC_DETAIL = gql`
  query GetSpecificFaultCodeDetails($dtc: String!) {
    getSpecificFaultCodeDetails(dtc: $dtc) {
      dfc_name
      description
      major_component
      sub_component
      type_of_error
      dtco_scantool
      fault_type_hex
      dtcsae_meterset_updated
      cause
      remedy
      healing_conditions
      lamp_startegy_mil
      heal_trigger
      mil
      svs
      awl
      inducement
      torque_limitation
      reaction
      severity
    }
  }
`;

const DTCDetailCard = ({ faultCode }: { faultCode: string }) => {

  const [faultCodeDetail, setFaultCodeDetail] = useState<VehicleFaultCode>();

  const { isFirstLoad, markLoaded  } = useFirstLoad();

  const { data, loading, error } = useQuery(GET_DTC_DETAIL, {
    variables: { dtc: faultCode },
    skip: !faultCode, // Skip the query if no faultCode is provided
    onCompleted: () => {
      setFaultCodeDetail(data?.getSpecificFaultCodeDetails);
    },
  });

  if (loading) {
    return (
      <Card elevation={3} sx={{ maxWidth: 600, margin: 'auto', mt: 2, maxHeight: 400 }}>
        <CardContent sx={{ overflowY: 'auto', maxHeight: '350px' }}>
          <Skeleton variant="text" width="80%" height={40} />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Skeleton variant="text" width="100%" height={24} />
            </Grid>
            <Grid item xs={6}>
              <Skeleton variant="text" width="100%" height={20} />
            </Grid>
            <Grid item xs={6}>
              <Skeleton variant="text" width="100%" height={20} />
            </Grid>
            <Grid item xs={6}>
              <Skeleton variant="text" width="100%" height={20} />
            </Grid>
            <Grid item xs={6}>
              <Skeleton variant="text" width="100%" height={20} />
            </Grid>
            <Grid item xs={6}>
              <Skeleton variant="text" width="100%" height={20} />
            </Grid>
            <Grid item xs={12}>
              <Skeleton variant="text" width="100%" height={20} />
            </Grid>
            <Grid item xs={12}>
              <Skeleton variant="text" width="100%" height={20} />
            </Grid>
            <Grid item xs={12}>
              <Skeleton variant="text" width="100%" height={20} />
            </Grid>
            <Grid item xs={12}>
              <Skeleton variant="text" width="100%" height={20} />
            </Grid>
            <Grid item xs={6}>
              <Skeleton variant="text" width="100%" height={20} />
            </Grid>
            <Grid item xs={6}>
              <Skeleton variant="text" width="100%" height={20} />
            </Grid>
            <Grid item xs={6}>
              <Skeleton variant="text" width="100%" height={20} />
            </Grid>
            <Grid item xs={6}>
              <Skeleton variant="text" width="100%" height={20} />
            </Grid>
            <Grid item xs={6}>
              <Skeleton variant="text" width="100%" height={20} />
            </Grid>
            <Grid item xs={6}>
              <Skeleton variant="text" width="100%" height={20} />
            </Grid>
            <Grid item xs={12}>
              <Skeleton variant="text" width="100%" height={20} />
            </Grid>
            <Grid item xs={12}>
              <Skeleton variant="text" width="100%" height={20} />
            </Grid>
            <Grid item xs={12}>
              <Skeleton variant="text" width="100%" height={20} />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  }

  if (error) return <Typography>Error: {error.message}</Typography>;
  if (!faultCodeDetail) return <Typography>No data available.</Typography>;

  return (
    <Card elevation={3} sx={{ maxWidth: 600, margin: 'auto', mt: 2, maxHeight: 400 }}>
      <CardContent sx={{ overflowY: 'auto', maxHeight: '350px' }}>
        <Typography variant="h5" gutterBottom>
          {faultCodeDetail.dfc_name}
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="subtitle1" color="textSecondary">
              {faultCodeDetail.description}
            </Typography>
          </Grid>

          <Grid item xs={6}>
            <Typography variant="body2">
              <strong>Major Component:</strong> {faultCodeDetail.major_component}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2">
              <strong>Sub Component:</strong> {faultCodeDetail.sub_component}
            </Typography>
          </Grid>

          <Grid item xs={6}>
            <Typography variant="body2">
              <strong>Type of Error:</strong> {faultCodeDetail.type_of_error}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2">
              <strong>DTC (Scantool):</strong> {faultCodeDetail.dtco_scantool}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2">
              <strong>Fault Type (Hex):</strong> {faultCodeDetail.fault_type_hex}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body2">
              <strong>DTC SAE Meter Set Updated:</strong> {faultCodeDetail.dtcsae_meterset_updated}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body2">
              <strong>Cause:</strong> {faultCodeDetail.cause}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body2">
              <strong>Remedy:</strong> {faultCodeDetail.remedy}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body2">
              <strong>Healing Conditions:</strong> {faultCodeDetail.healing_conditions}
            </Typography>
          </Grid>

          <Grid item xs={6}>
            <Typography variant="body2">
              <strong>Lamp Strategy MIL:</strong> {faultCodeDetail.lamp_startegy_mil}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2">
              <strong>Heal Trigger:</strong> {faultCodeDetail.heal_trigger}
            </Typography>
          </Grid>

          <Grid item xs={6}>
            <Typography variant="body2">
              <strong>MIL:</strong> {faultCodeDetail.mil}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2">
              <strong>SVS:</strong> {faultCodeDetail.svs}
            </Typography>
          </Grid>

          <Grid item xs={6}>
            <Typography variant="body2">
              <strong>AWL:</strong> {faultCodeDetail.awl}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2">
              <strong>Inducement:</strong> {faultCodeDetail.inducement}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body2">
              <strong>Torque Limitation:</strong> {faultCodeDetail.torque_limitation}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body2">
              <strong>Reaction:</strong> {faultCodeDetail.reaction}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2">
              <strong>Severity:</strong> {faultCodeDetail.severity}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default DTCDetailCard;
