import { Divider, Drawer, List, Grid, IconButton, Box } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import theme from "../../theme";
import SmlIcon from "../../assets/png/sml-warroom.png";
import DrawerMenuItem from "../../components/Drawer/DrawerMenuItem";
import DrawerItems from "../../components/Drawer/DrawerItems";
import { useDrawerToggle } from "../../hooks/useDrawerToggle";

function MainDrawer() {
    const { drawerOpen, toggleDrawer } = useDrawerToggle();

    return (
        <Drawer
            anchor="left"
            variant="permanent"
            open={drawerOpen}
            sx={{
                width: drawerOpen ? "16%" : "72px",
                flexShrink: 0,
                overflow: "hidden", // Prevent horizontal scrolling
                "& .MuiDrawer-paper": {
                    width: drawerOpen ? "16%" : "72px",
                    boxSizing: "border-box",
                    transition: "width 0.3s",
                    overflow: "hidden", // Prevent horizontal scrolling on the paper
                    borderTopRightRadius: "30px",
                    borderBottomRightRadius: "30px"
                },
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: drawerOpen ? "flex-end" : "center",
                    padding: 2,
                    backgroundColor: "#E82428",
                    overflow: "hidden",
                }}
            >
                <IconButton onClick={toggleDrawer}>
                    {drawerOpen ? (
                        <MenuIcon sx={{ color: "#fff" }} />
                    ) : (
                        <img src={SmlIcon} alt="SML Logo" width="32px" />
                    )}
                </IconButton>
            </Box>
            {drawerOpen && (
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginBottom: 2, // Add margin bottom to create space between the logo and the list
                        overflow: "hidden",
                    }}
                >
                    <img src={SmlIcon} alt="Another logo" width="100%" />
                </Box>
            )}
            <List sx={{ p: "0", flexGrow: 1, overflow: "auto" }}>
                {DrawerItems.map((item, index) => (
                    <DrawerMenuItem item={item} key={index + "-menuitem"} />
                ))}
            </List>
        </Drawer>
    );
}

export default MainDrawer;
