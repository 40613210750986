import React, { useEffect, useState } from "react";
import Running from "../../assets/smlIcons/Dashboard Icons/Running.svg";
import Stopped from "../../assets/smlIcons/Dashboard Icons/Stopped.svg";
import NoGPS from "../../assets/smlIcons/Dashboard Icons/No GPS.svg";
import Offline from "../../assets/smlIcons/Dashboard Icons/Offline.svg";
import Idle from "../../assets/smlIcons/Dashboard Icons/Idling.svg";
import NoData from "../../assets/smlIcons/Dashboard Icons/No Data.svg";
import Good from "../../assets/smlIcons/PerformanceIcons/Good.svg";
import MinorIssue from "../../assets/smlIcons/PerformanceIcons/Minor.svg";
import MajorIssue from "../../assets/smlIcons/PerformanceIcons/Major.svg";
import { Box, Grid, Paper, Typography, Skeleton, CircularProgress, IconButton } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import dayjs from "dayjs";
import TimePeriodSelector from "../../components/Miscellaneous/TimePeriodSelector";
import { gql, useLazyQuery, useQuery } from "@apollo/client";
import { DPFParams, IUMPRData, LiveVehicle, SoftwareVersions, VehicleRecord } from "../../types";
import GearPercentageTable from "../../components/VehiclePerformance/GearTable";
import FuelAdblueTable from "../../components/VehiclePerformance/FuelandAdblue";
import ACUsageTable from "../../components/VehiclePerformance/ACUsage";
import DriveModeTable from "../../components/VehiclePerformance/DriveModeTable";
import SoftwareVersionTable from "../../components/VehiclePerformance/SoftwareVersionTable";
import IUMPRTable from "../../components/VehiclePerformance/IUMPRTable";
import DPFParamsTable from "../../components/VehiclePerformance/DPFParamsTable"; // Import DPFParamsTable
import theme from "../../theme";
import CountCard from "../../components/dashboard/VehicleCountComponents/CountCard";
import { useNavigate, useLocation } from "react-router-dom";
import VehicleCard from "../../components/VehicleDiagnostics/VehicleInfoCard";
import DataPercentageTable from "../../components/VehiclePerformance/GearTable";
import { useFirstLoad } from "../../hooks/useSkeloton";
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';

const GET_VEHICLE_PERFORMANCE = gql`
  query GetVehicleHistoryData($uniqueId: String!, $fromTs: Int!, $toTs: Int!) {
    getVehicleHistoryData(uniqueId: $uniqueId, fromTs: $fromTs, toTs: $toTs) {
      date
      gear_data
      ac_usage
      torque_data
      speed_data
      vehicle_running_mode
      total_obd_distance
      total_engine_hour
      total_fuel_consumed
      mileage
      total_adblue_consumed
      avg_speed
      dtc_codes
      lamps
      ems_ecu_software_version
      meter_set_software_version
      firmwareversion
      soot_mass
      number_of_regeneration_demands
      number_of_successful_regenerations
      distance_in_kms_for_last_regeneration
      obd_monitoring_conditions_encountered
      ignition_cycle_counter
      nmhc_catalyst_monitor_completion
      nmhc_catalyst_monitor_conditions_encountered
      nox_catalyst_monitor_completion
      nox_catalyst_monitor_conditions_encountered
      nox_adsorber_monitor_completion
      nox_adsorber_monitor_conditions_encountered
      pm_filter_monitor_completion
      pm_filter_monitor_conditions_encountered
      exhaust_gas_sensor_monitor_completion
      exhaust_gas_sensor_monitor_conditions_encountered
      egr_value_vvt_monitor_completion
      egr_value_vvt_monitor_conditions_encountered
      boost_pressure_monitor_completion
      boost_pressure_monitor_conditions_encountered
      fuel_monitor_completion
      fuel_monitor_conditions_encountered
      dpf {
      sm
      ts
      odo
      hrm
      srgn
      rgnd
    }
    }
  }
`;

const GET_ALL_VEHICLE_LIVE_STATUS = gql`
  query GetAllVehicleLiveStatus {
    getAllVehicleLiveStatus {
      uniqueId
      vehicleNumber
      chassisNumber
    }
  }
`;

const GET_VEHICLE_LIVE_DATA = gql`
  query GetVehicleLiveData($uniqueId: String!) {
    getVehicleLiveData(uniqueId: $uniqueId) {
      date
      gear_data
      ac_usage
      speed_data
      trackingStatus
      healthStatus
      vehicle_running_mode
      total_obd_distance
      total_engine_hour
      total_fuel_consumed
      mileage
      total_adblue_consumed
      avg_speed
      dtc_codes
      lamps
      ems_ecu_software_version
      meter_set_software_version
      firmwareversion
      soot_mass
      number_of_regeneration_demands
      number_of_successful_regenerations
      distance_in_kms_for_last_regeneration
      obd_monitoring_conditions_encountered
      ignition_cycle_counter
      nmhc_catalyst_monitor_completion
      nmhc_catalyst_monitor_conditions_encountered
      nox_catalyst_monitor_completion
      nox_catalyst_monitor_conditions_encountered
      nox_adsorber_monitor_completion
      nox_adsorber_monitor_conditions_encountered
      pm_filter_monitor_completion
      pm_filter_monitor_conditions_encountered
      exhaust_gas_sensor_monitor_completion
      exhaust_gas_sensor_monitor_conditions_encountered
      egr_value_vvt_monitor_completion
      egr_value_vvt_monitor_conditions_encountered
      boost_pressure_monitor_completion
      boost_pressure_monitor_conditions_encountered
      fuel_monitor_completion
      fuel_monitor_conditions_encountered
    }
  }
`;

const GET_VEHICLE_SALES_DATA = gql`query GetSalesData($vin: [String!]) {
  getSalesData(vin: $vin) {
    chasisNo
    engineNo
    applicationType
    modelCategory
    productName
    vehicleType
    gvw
    passengerCapacity
    wheelBase
    engineType
    brakeType
    emissionStandard
    dealerCode
    dealerContactName
    dealerContactNumber
    fuelType
    adblueTankCapacity
    yearOfManufacturing
  }
}`;


type TrackingStatus = "RUNNING" | "IDLE" | "HALT" | "NO_GPS" | "NODATA" | "OFFLINE";
type HealthStatus = "GOOD" | "MINOR_ISSUE" | "MAJOR_ISSUE";


const VehiclePerformance = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const queryUniqueId = queryParams.get("uniqueId");
  const autoFetch = queryParams.get("autoFetch") === "true";

  const [allVehicleLiveStatus, setAllVehicleLiveStatus] = useState<
    LiveVehicle[]
  >([]);
  const [selectedVehicle, setSelectedVehicle] = useState<LiveVehicle | null>(
    null
  );
  const [fromTime, setFromTime] = useState<number>(
    Math.floor(new Date().setHours(0, 0, 0, 0) / 1000)
  );
  const [option, setOption] = useState<string>("WEEK");
  const [toTime, setToTime] = useState<number>(Math.floor((Date.now() - 2 * 24 * 60 * 60 * 1000) / 1000));
  const [isDefaultSelected, setIsDefaultSelected] = useState(true);
  const [vehiclePerformanceData, setVehiclePerformanceData] =
    useState<VehicleRecord | null>(null);
  const [livePerformanceData, setLivePerformanceData] =
    useState<VehicleRecord | null>(null);
  const [customerData, setCustomerData] = useState<any>();
  const { isFirstLoad, markLoaded } = useFirstLoad();
  const [loadingScreen, setLoadingScreen] = useState(false);
  const [submitClicked, setSubmitClicked] = useState(false);
  const [committedFromTime, setCommittedFromTime] = useState(fromTime);
  const [committedToTime, setCommittedToTime] = useState(toTime);
  const [getAllVehicleLiveStatus] = useLazyQuery(GET_ALL_VEHICLE_LIVE_STATUS, {
    onCompleted: (data: { getAllVehicleLiveStatus: LiveVehicle[] }) => {
      setAllVehicleLiveStatus(data.getAllVehicleLiveStatus);
    },
  });
  const [getSalesData, { data: salesData, loading: salesLoading, error: salesError }] = useLazyQuery(GET_VEHICLE_SALES_DATA);

  const [
    getVehicleLiveData,
    {
      loading: vehicleLiveDataLoading,
      error: vehicleLiveDataError,
      data: vehicleLiveDataResult,
    },
  ] = useLazyQuery(GET_VEHICLE_LIVE_DATA, {
    onCompleted: (data) => {
      setLivePerformanceData(data.getVehicleLiveData);
    },
  });

  useEffect(() => {
    getAllVehicleLiveStatus();
  }, [getAllVehicleLiveStatus]);

  useEffect(() => {
    if (selectedVehicle) {
      getVehicleLiveData({ variables: { uniqueId: selectedVehicle.uniqueId } });
    }
  }, [selectedVehicle, getVehicleLiveData]);

  const [getVehiclePerformanceData] = useLazyQuery(GET_VEHICLE_PERFORMANCE, {
    onCompleted: (data) => {
      setVehiclePerformanceData(data.getVehicleHistoryData);
    },
  });

  const {
    startPolling,
    stopPolling,
    data: liveData,
  } = useQuery(GET_VEHICLE_LIVE_DATA, {
    variables: { uniqueId: selectedVehicle?.uniqueId },
    skip: (!selectedVehicle && !vehiclePerformanceData) || vehiclePerformanceData === null,
    pollInterval: 10000, // Poll every 10 seconds
    onCompleted: (data) => {
      if (data && data.getVehicleLiveData) {
        setLivePerformanceData(data.getVehicleLiveData);
      }
    },
  });

  // useEffect(() => {
  //   if (liveData && liveData.getVehicleLiveData) {
  //     setLivePerformanceData(liveData.getVehicleLiveData);
  //   }
  // }, [liveData, selectedVehicle, vehiclePerformanceData]);
  // Automatically set the selected vehicle if queryUniqueId is present
  useEffect(() => {
    if (queryUniqueId && allVehicleLiveStatus.length > 0) {
      const selectedVehicle = allVehicleLiveStatus.find(
        (vehicle) => vehicle.uniqueId === queryUniqueId
      );
      if (selectedVehicle) {
        setSelectedVehicle(selectedVehicle);
      }
    }
  }, [queryUniqueId, allVehicleLiveStatus]);

  useEffect(() => {
    setIsDefaultSelected(option === "CUSTOM" || option === "");
    if (fromTime && fromTime === toTime) {
      // Adjust to full day if fromTime and toTime are the same
      const adjustedFromTime = dayjs.unix(fromTime).startOf("day").unix(); // Start of the day
      const adjustedToTime = dayjs.unix(toTime).endOf("day").unix(); // End of the day
      setFromTime(adjustedFromTime);
      setToTime(adjustedToTime);
    }
    if (option !== "CUSTOM" && option !== "") {
      const currentTimeInSeconds = Math.floor(new Date().getTime() / 1000);
      setToTime(currentTimeInSeconds - 2 * 24 * 60 * 60);
      switch (option) {
        case "HOUR":
          setFromTime(currentTimeInSeconds - 60 * 60); // Last 1 hour
          break;
        case "DAY":
          setFromTime(currentTimeInSeconds - 24 * 60 * 60); // Last 1 day
          break;
        case "WEEK":
          setFromTime(currentTimeInSeconds - 7 * 24 * 60 * 60); // Last 7 days
          break;
        case "MONTH":
          setFromTime(currentTimeInSeconds - 30 * 24 * 60 * 60); // Last 30 days
          break;
        default:
          break;
      }
    }
  }, [fromTime, option, toTime]);
  
  // Fetch vehicle performance data and sales data when the selected vehicle changes
  useEffect(() => {
    if (selectedVehicle && autoFetch) {
      setCommittedFromTime(fromTime);
      setCommittedToTime(toTime);
      getVehiclePerformanceData({
        variables: {
          uniqueId: selectedVehicle.uniqueId,
          fromTs: fromTime,
          toTs: toTime,
        },
      });
      getVehicleLiveData({
        variables: { uniqueId: selectedVehicle.uniqueId },
      });
      if (selectedVehicle.chassisNumber) {
        getSalesData({
          variables: { vin: [selectedVehicle.chassisNumber] },
        });
      }
    }
  }, [selectedVehicle, autoFetch, fromTime, toTime, getVehiclePerformanceData, getVehicleLiveData, getSalesData]);
  

  const handleOptionChange = (e: React.ChangeEvent<{ value: string }>) => {
    e.preventDefault();
    setOption(e.target.value);
    setLoadingScreen(true);
  };

  const handleSubmit = () => {
    if (fromTime && fromTime >= toTime) {
      enqueueSnackbar("Date range provided is wrong");
      return; // Stop further execution
    } else if (
      (fromTime && fromTime > dayjs().unix()) ||
      toTime > dayjs().unix()
    ) {
      enqueueSnackbar("Future dates are not allowed");
      return; // Stop further execution
    }

    setLoadingScreen(false); // Start loading
    setFromTime(fromTime);
    setToTime(toTime);

    if (selectedVehicle) {
      getVehiclePerformanceData({
        variables: {
          uniqueId: selectedVehicle.uniqueId,
          fromTs: fromTime,
          toTs: toTime,
        },
      });

      if (selectedVehicle.chassisNumber) {
        getSalesData({
          variables: { vin: [selectedVehicle.chassisNumber] },
        });
      }
    }
    setSubmitClicked(true);
  };

  useEffect(() => {
    if (salesData && salesData.getSalesData) {
      setCustomerData(salesData.getSalesData[0]); // Assuming getSalesData returns an array
    }
  }, [salesData]);

  const handleSelectVehicle = (
    event: React.ChangeEvent<{}>,
    newValue: LiveVehicle
  ) => {
    setSelectedVehicle(newValue);
    setVehiclePerformanceData(null);
    setLivePerformanceData(null);
    setCustomerData(null);
    setLoadingScreen(true);
    markLoaded();
  };

  const trackingStatusColors = {
    RUNNING: theme.palette.lightgreen.main,
    IDLE: theme.palette.lightyellow.main,
    HALT: theme.palette.lightred.main,
    NO_GPS: theme.palette.grey2.main,
    NODATA: theme.palette.grey2.main,
    OFFLINE: theme.palette.grey2.main,
  };

  const healthStatusColors = {
    GOOD: "#C6EFCE",
    MINOR_ISSUE: "#FFEB9C",
    MAJOR_ISSUE: "#FFC7CE",
  };

  const gearLabels: { [key: string]: string } = {
    "0": "Neutral",
    "1": "First",
    "2": "Second",
    "3": "Third",
    "4": "Fourth",
    "5": "Fifth",
    "6": "Sixth",
    "-1": "Reverse",
    // Add other mappings if needed
  };

  const speedLabels = {
    "0-10": "0-10 km/h",
    "10-20": "10-20 km/h",
    "20-30": "20-30 km/h",
    "30-40": "30-40 km/h",
    "40-50": "40-50 km/h",
    "50-60": "50-60 km/h",
    "60-70": "60-70 km/h",
    "70-80": "70-80 km/h",
    "80+": "80 km/h and above",
  };

  const torqueLabels = {
    "0-50": "0-50 Nm",
    "50-100": "50-100 Nm",
    "100-150": "100-150 Nm",
    "150-200": "150-200 Nm",
    "200-250": "200-250 Nm",
    "250-300": "250-300 Nm",
    "300-350": "300-350 Nm",
    "350-400": "350-400 Nm",
    "400-450": "400-450 Nm",
    "450+": "450+ Nm"
  }

  const extractIUMPRData = (data: VehicleRecord): IUMPRData => {
    return {
      obd_monitoring_conditions_encountered:
        data.obd_monitoring_conditions_encountered,
      ignition_cycle_counter: data.ignition_cycle_counter,
      nmhc_catalyst_monitor_completion_counts:
        data.nmhc_catalyst_monitor_completion,
      nmhc_catalyst_monitor_conditions_encountered:
        data.nmhc_catalyst_monitor_conditions_encountered,
      nox_catalyst_monitor_completion_counts:
        data.nox_catalyst_monitor_completion,
      nox_catalyst_monitor_conditions_encountered_counts:
        data.nox_catalyst_monitor_conditions_encountered,
      nox_adsorber_monitor_completion_counts:
        data.nox_adsorber_monitor_completion,
      nox_adsorber_monitor_conditions_encountered:
        data.nox_adsorber_monitor_conditions_encountered,
      pm_filter_monitor_completion_counts: data.pm_filter_monitor_completion,
      pm_filter_monitor_conditions_encountered_counts:
        data.pm_filter_monitor_conditions_encountered,
      exhaust_gas_sensor_monitor_completion_counts:
        data.exhaust_gas_sensor_monitor_completion,
      exhaust_gas_sensor_conditions_encountered_counts:
        data.exhaust_gas_sensor_monitor_conditions_encountered,
      egr_vvt_monitor_completion_counts: data.egr_value_vvt_monitor_completion,
      egr_vvt_conditions_encountered_counts:
        data.egr_value_vvt_monitor_conditions_encountered,
      boost_pressure_monitor_completion_counts:
        data.boost_pressure_monitor_completion,
      boost_pressure_monitor_conditions_encountered_counts:
        data.boost_pressure_monitor_conditions_encountered,
      fuel_monitor_completion_counts: data.fuel_monitor_completion,
      fuel_monitor_conditions_encountered_counts:
        data.fuel_monitor_conditions_encountered,
    };
  };

  const extractDPFParams = (data: VehicleRecord): DPFParams => {
    return {
      sootMass: data.soot_mass,
      regenerationDemands: data.number_of_regeneration_demands,
      successfulRegenerations: data.number_of_successful_regenerations,
      lastSuccessfulRegeneration: data.distance_in_kms_for_last_regeneration,
      odometer: data.total_obd_distance,
      timestamp: data.date,
      dpf: data.dpf
    };
  };

  const extractSoftwareData = (data: VehicleRecord): SoftwareVersions => {
    return {
      firmwareVersion: data.firmwareversion ?? 'NA',
      emsEcuSoftwareVersion: data.ems_ecu_software_version ?? 'NA',
      meterSetSoftwareVersion: data.meter_set_software_version ?? 'NA'
    }
  }

  const getTrackingColor = (status: string): string => {
    return trackingStatusColors[status as TrackingStatus] || theme.palette.grey4.main;
  };

  const getHealthColor = (status: string): string => {
    return healthStatusColors[status as HealthStatus] || theme.palette.grey4.main;
  };

  const handleLatLngClick = (uniqueId: string) => {
    window.open(
      `https://pro.smlsaarthi.com/tracking/vehicle-details/${uniqueId}`,
      "_blank"
    );
  };

  const trackingStatusIcon = {
    RUNNING: Running,
    IDLE: Idle,
    HALT: Stopped,
    NO_GPS: NoGPS,
    NODATA: NoData,
    OFFLINE: Offline,
  };

  const healthStatusIcon = {
    GOOD: Good,
    MINOR_ISSUE: MinorIssue,
    MAJOR_ISSUE: MajorIssue,
  }

  const prepareAcUsageData = (acUsage: any) => {
    return {
      ON: acUsage?.ON || 0,
      OFF: acUsage?.OFF || 0,
    };
  };

  const prepareDriveModeUsageData = (driveMode: any) => {
    return {
      ECO_MODE: driveMode.ECO_MODE || 0,
      POWER_MODE: driveMode.POWER_MODE || 0
    }
  }


  return (
    <Box sx={{ backgroundColor: "#F6F5F4", padding: 2 }}>
      {/* Header Section */}
      <Grid container spacing={2} sx={{ padding: 1 }}>
        {/* ...existing code... */}
        <Grid item xs={12} sm={4}>
          <Typography sx={{ fontSize: 32, fontWeight: "semi-bold" }}>
            Vehicle Performance Analytics
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <TimePeriodSelector
            option={option}
            setFromTs={setFromTime}
            fromTs={fromTime}
            toTs={toTime}
            setToTs={setToTime}
            onOptionChange={handleOptionChange}
            handleSubmit={handleSubmit}
            handleSelectVehicle={handleSelectVehicle}
            vehicles={allVehicleLiveStatus}
            isDefaultSelected={isDefaultSelected}
            selectedVehicle={selectedVehicle}
            isDashBoard={false}
            isDownload={false}
          />
        </Grid>
      </Grid>

      {/* Main Content */}
      {loadingScreen ? (
        <Box textAlign="center">
          {/* ...maybe a skeleton or loader... */}
        </Box>
      ) : selectedVehicle ? (
        <Grid container spacing={2} sx={{ padding: 1 }}>
          {/* ...existing code for vehicle details, tables, etc... */}
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} md={4}>
                {livePerformanceData && (
                  <Typography variant="caption" color="textSecondary">
                    Live data updated at: {new Date().toLocaleTimeString()}
                  </Typography>
                )}
              </Grid>
              <Grid container spacing={1}>
                {selectedVehicle && (
                  <Grid item xs={12} md={12}>
                      <VehicleCard vehicle={selectedVehicle} customerData={customerData} />
                  </Grid>
                )}
                <Grid item xs={12} sm={5}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Box>
                        {selectedVehicle && vehiclePerformanceData && livePerformanceData && customerData && (
                          <Paper sx={{ padding: 2 }}>
                            <Grid container spacing={2}>
                              <Grid item xs={12} md={6}>
                                <CountCard
                                  value={livePerformanceData.healthStatus || "NA"}
                                  icon={healthStatusIcon[livePerformanceData.healthStatus as HealthStatus]}
                                  title={"Health Status"}
                                  color={getHealthColor(livePerformanceData.healthStatus || "")}
                                  onClick={() => { }}
                                />
                              </Grid>
                              <Grid item xs={12} md={6}>
                                <Box sx={{ position: 'relative' }}>
                                  <CountCard
                                    value={livePerformanceData.trackingStatus || "NA"}
                                    icon={trackingStatusIcon[livePerformanceData.trackingStatus as TrackingStatus]}
                                    title={"Tracking Status"}
                                    color={getTrackingColor(livePerformanceData.trackingStatus || "")}
                                    onClick={() => { }}
                                  />
                                  <IconButton
                                    sx={{
                                      position: 'absolute',
                                      top: 8,
                                      right: 8,
                                      zIndex: 10, // Ensure it stays on top
                                      color: 'inherit',
                                    }}
                                    onClick={() => { handleLatLngClick(selectedVehicle?.uniqueId!) }}
                                  >
                                    <ArrowOutwardIcon />
                                  </IconButton>
                                </Box>
                              </Grid>
                            </Grid>
                          </Paper>
                        )}
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <DataPercentageTable
                        dataProp={vehiclePerformanceData?.gear_data || {}}
                        labels={gearLabels}
                        dataType={"Gear"}
                        loading={!vehiclePerformanceData}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <ACUsageTable
                        loading={!vehiclePerformanceData}
                        acUsage={prepareAcUsageData(vehiclePerformanceData?.ac_usage)}
                        liveACUsage={livePerformanceData?.ac_usage}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <DriveModeTable
                        loading={!vehiclePerformanceData}
                        driveMode={prepareDriveModeUsageData(vehiclePerformanceData?.vehicle_running_mode || {})}
                        liveDriveMode={livePerformanceData?.vehicle_running_mode}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FuelAdblueTable
                    fuelAdblueData={{
                      total_obd_distance: vehiclePerformanceData?.total_obd_distance || 0,
                      total_engine_hour: vehiclePerformanceData?.total_engine_hour || 0,
                      total_fuel_consumed: vehiclePerformanceData?.total_fuel_consumed || 0,
                      mileage: vehiclePerformanceData?.mileage || 0,
                      total_adblue_consumed: vehiclePerformanceData?.total_adblue_consumed || 0,
                      avg_speed: vehiclePerformanceData?.avg_speed || 0,
                    }}
                    liveFuelAdblueData={
                      livePerformanceData
                        ? {
                          total_obd_distance: livePerformanceData.total_obd_distance,
                          total_engine_hour: livePerformanceData.total_engine_hour,
                          total_fuel_consumed: livePerformanceData.total_fuel_consumed,
                          mileage: livePerformanceData.mileage,
                          total_adblue_consumed: livePerformanceData.total_adblue_consumed,
                          avg_speed: livePerformanceData.avg_speed,
                        }
                        : null
                    }
                    loading={!vehiclePerformanceData} // new loading prop
                  />
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={5}>
                  <Grid item xs={12}>
                    <DataPercentageTable
                      dataProp={vehiclePerformanceData?.speed_data || {}}
                      labels={speedLabels}
                      dataType={"Speed"}
                      loading={!vehiclePerformanceData}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <DataPercentageTable
                      dataProp={vehiclePerformanceData?.torque_data || {}}
                      labels={torqueLabels}
                      dataType={"Torque"}
                      loading={!vehiclePerformanceData}
                    />
                  </Grid>
                  {vehiclePerformanceData && livePerformanceData ? (
                    <SoftwareVersionTable softwareVersions={livePerformanceData ? extractSoftwareData(livePerformanceData) : null} />
                  ) : (
                    isFirstLoad && <Skeleton variant="rectangular" width="100%" height={118} />
                  )}
                  <Grid item xs={12}>
                    <DPFParamsTable
                      loading={!vehiclePerformanceData}
                      dpfHistory={vehiclePerformanceData ? extractDPFParams(vehiclePerformanceData) : {} as any}
                      liveDpf={livePerformanceData ? extractDPFParams(livePerformanceData) : null}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={7}>
                  <IUMPRTable
                    loading={!vehiclePerformanceData}
                    historicData={
                      vehiclePerformanceData
                        ? extractIUMPRData(vehiclePerformanceData)
                        : {} as any
                    }
                    liveData={
                      livePerformanceData
                        ? extractIUMPRData(livePerformanceData)
                        : null
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : autoFetch ? (
        <Box
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
          <CircularProgress />
        </Box>
      ) : (
        <Grid container justifyContent="center" alignItems="center" sx={{ height: "80vh" }}>
          <Typography>
            No vehicle selected. Please select a vehicle to view performance data.
          </Typography>
        </Grid>
      )}
    </Box>
  );
};

export default VehiclePerformance;