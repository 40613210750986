import React, { useState } from "react";
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Box,
    Typography,
    TableBody,
    TableHead,
    TableCell,
    TableRow,
    Table,
    IconButton,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { utils, writeFile } from "xlsx";
import { ColumnDef } from "@tanstack/react-table";

// Example column definitions (import your own definitions here)
import {
    adjustmentParamsColumns,
    datasetColumns,
    dtcColumns,
    ecuColumns,
    flashingInformationColumns,
    liveParametersColumns,
    materialInfoColumns,
    SpeedLimiterColumns,
    toolActivityColumns,
    toolColumns,
    vehicleFlashedInfoColumns,
} from "../../components/ToolActivity/ColumnDetail";

interface VehicleCardProps {
    data: any;
    vinNo: string;
}

type ColumnDetails = {
    [key: string]: { heading: string; columns: ColumnDef<any>[] };
};

// Define column details mapping
const columnDetails: ColumnDetails = {
    Ecu: { heading: "Ecu", columns: ecuColumns },
    GeneratedDTC: { heading: "Generated DTC", columns: dtcColumns },
    MaterialInfo: { heading: "Material Information", columns: materialInfoColumns },
    Dataset: { heading: "Dataset", columns: datasetColumns },
    VehicleFlashedInformation: { heading: "Vehicle Flashed Information", columns: vehicleFlashedInfoColumns },
    FlashingAndDtcReport: { heading: "Flashing And DTC Report", columns: flashingInformationColumns },
    Tools: { heading: "Tools", columns: toolColumns },
    ToolActivities: { heading: "Tool Activity", columns: toolActivityColumns },
    LiveParameters: { heading: "Live Parameters", columns: liveParametersColumns },
    AdjustmentParameters: { heading: "Adjustment Parameters", columns: adjustmentParamsColumns },
    VehicleSpeedLimiter: { heading: "Speed Limiter", columns: SpeedLimiterColumns },
};

export const VehicleSearch: React.FC<VehicleCardProps> = ({ data, vinNo }) => {
    const { generatedDTC, vehicleFlashedInformation, liveParameters, adjustmentParameters } = data || {};
    const [expandedAccordion, setExpandedAccordion] = useState<string | null>(null);
    const [expandedAccordions, setExpandedAccordions] = useState<string[]>([]);
    const [columnsByPanel, setColumnsByPanel] = useState<{ [key: string]: ColumnDef<any>[] }>({});
    const [allExpanded, setAllExpanded] = useState(false);

    // Update columns when a panel expands
    const handleAccordionChange = (panel: string) => (_event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpandedAccordions((prev) => {
            const newExpanded = isExpanded ? [...prev, panel] : prev.filter((item) => item !== panel);
            if (isExpanded) {
                setColumnsByPanel((prevColumns) => ({
                    ...prevColumns,
                    [panel]: columnDetails[panel]?.columns || [],
                }));
            }
            return newExpanded;
        });
    };

    // Expand all panels
    const toggleExpandAll = () => {
        const panels = [
            "GeneratedDTC",
            "VehicleFlashedInformation",
            "LiveParameters",
            "AdjustmentParameters",
        ];
        if (allExpanded) {
            // Collapse all
            setExpandedAccordions([]);
            setColumnsByPanel({});
        } else {
            // Expand all and set columns for each panel
            setExpandedAccordions(panels);
            const allColumns = panels.reduce((acc, panel) => {
                acc[panel] = columnDetails[panel]?.columns || [];
                return acc;
            }, {} as { [key: string]: ColumnDef<any>[] });
            setColumnsByPanel(allColumns);
        }
        setAllExpanded(!allExpanded);
    };

    // Render individual accordion
    const renderAccordion = (title: string, count: number, details: any[], panel: string) => (
        <Accordion
            expanded={expandedAccordions.includes(panel)} // Check if this panel is expanded
            onChange={handleAccordionChange(panel)}
        >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                sx={{ bgcolor: "white", borderBottom: 1, borderColor: "grey.300" }}
            >
                <Typography variant="subtitle1" fontWeight="bold" color="text.primary">
                    {`${title} (${count})`}
                </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ p: 2, bgcolor: "white" }}>
                {details && details.length > 0 ? (
                    <>
                        {renderDetails(details, columnsByPanel[panel] || [])}
                    </>
                ) : (
                    <Typography variant="body2" color="text.secondary">
                        No data available.
                    </Typography>
                )}
            </AccordionDetails>
        </Accordion>
    );

    // Helper function for rendering table details
    const renderDetails = (details: any[], selectedColumns: ColumnDef<any>[]) => (
        <Box sx={{ maxHeight: "400px", overflowY: "auto" }}>
            <Table>
                <TableHead>
                    <TableRow>
                        {selectedColumns.map((col: any) => (
                            <TableCell key={col.accessorKey}
                                sx={{
                                    textTransform: "uppercase",
                                    fontWeight: "bold", // Makes text bold
                                    position: "sticky", // Sticky header
                                    top: 0, // Anchors it at the top
                                    backgroundColor: "#f8f9fa", // Background color for better visibility
                                    zIndex: 1, // Ensures it stays above content
                                }} 
                                style={{ textTransform: "uppercase" }}>
                                {col.header}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {details.map((item, index) => (
                        <TableRow key={index}>
                            {selectedColumns.map((col: any) => (
                                <TableCell key={col.accessorKey}>
                                    {/* Use the custom render function if defined */}
                                    {col.cell ? (
                                        col.cell({ cell: { getValue: () => item[col.accessorKey] }, row: { original: item } })
                                    ) : (
                                        item[col.accessorKey] || "N/A"
                                    )}
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Box>
    );

    return (
        <Box>
            {/* Expand All Button */}
            <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 2 }}>
                <IconButton
                    onClick={toggleExpandAll}
                    sx={{ bgcolor: "#000", borderBottom: 1, borderColor: "grey.300" }}
                >
                    {allExpanded ? <ExpandLessIcon sx={{ color: "#fff" }} /> : <ExpandMoreIcon sx={{ color: "#fff" }} />}
                </IconButton>
            </Box>

            {/* Render Accordions */}
            {renderAccordion(
                "Generated DTC",
                generatedDTC?.count || 0,
                generatedDTC?.data || [],
                "GeneratedDTC"
            )}
            {renderAccordion(
                "Vehicle Flashed Information",
                vehicleFlashedInformation?.count || 0,
                vehicleFlashedInformation?.data || [],
                "VehicleFlashedInformation"
            )}
            {renderAccordion(
                "Live Parameters",
                liveParameters?.count || 0,
                liveParameters?.data || [],
                "LiveParameters"
            )}
            {renderAccordion(
                "Adjustment Parameters",
                adjustmentParameters?.count || 0,
                adjustmentParameters?.data || [],
                "AdjustmentParameters"
            )}
        </Box>
    );
};

