import React, { useState } from "react";
import { Chip, Button } from "@mui/material";
import { DTC } from "../../types";

interface Props {
  row: {
    original: DTC;
    id: string | number;
  };
}

const DtcCodesColumn: React.FC<Props> = ({ row }) => {
  const formattedDtcCodes = row.original.dtcCodes
    .split(",")
    .map((code: string) => code.trim())
    .filter((code: string) => code); // Ensure this array only contains strings

  const maxVisibleCodes = 7;
  const [expandedRows, setExpandedRows] = useState<Record<string | number, boolean>>({});

  const toggleExpand = (rowId: string | number) => {
    setExpandedRows((prev) => ({
      ...prev,
      [rowId]: !prev[rowId],
    }));
  };

  const isExpanded = expandedRows[row.id] || false;

  return (
    <div style={{ display: "flex", flexWrap: "wrap", gap: "8px" }}>
      {(isExpanded ? formattedDtcCodes : formattedDtcCodes.slice(0, maxVisibleCodes)).map((code: string, index: number) => {
        let backgroundColor = "#E5E7EB";
        let textColor = "black";

        if (code.includes("[INACTIVE]")) {
          backgroundColor = "#BBF7D0";
        } else if (code.includes("[ACTIVE]")) {
          backgroundColor = "#FECACA";
        }

        return (
          <Chip
            key={index}
            label={code}
            sx={{
              backgroundColor,
              color: textColor,
              fontSize: "0.875rem", // Matches the text-sm class in Bootstrap
              padding: "4px 8px", // Matches px-2 py-1 in Bootstrap
            }}
          />
        );
      })}
      {formattedDtcCodes.length > maxVisibleCodes && (
        <Button
          variant="text"
          size="small"
          sx={{ marginTop: "8px", textTransform: "none", color: "blue" }}
          onClick={() => toggleExpand(row.id)}
        >
          {isExpanded ? "Show Less" : "Show More"}
        </Button>
      )}
    </div>
  );
};

export default DtcCodesColumn;
