import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    TextField,
    Typography,
} from "@mui/material";
import ServiceTable from "./ServiceTable";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useState } from "react";

const GET_ALL_CCM_DETAILS = gql`
query GetAllCCMDetails {
  getAllCCMDetails {
    id
    name
    employeeno
    email
    mobile
    createdat
    updatedat
    zsmDetails {
      zsmName
      zsmEmployeeNo
    }
  }
}
`;

const ADD_CCM = gql`
mutation AddCCM(
    $name: String!
    $employeeno: String!
    $email: String!
    $mobile: String!
) {
    addCCMDetails(name: $name, employeeno: $employeeno, email: $email, mobile: $mobile) {
        status
        message
    }
}
`;

const ASSIGN_CCM_TO_ZSM = gql`
mutation AssignZSMToCCM($ccmEmployeeNo: String!, $zsmEmployeeNo: String!) {
  assignZSMToCCM(ccmEmployeeNo: $ccmEmployeeNo, zsmEmployeeNo: $zsmEmployeeNo) {
    status
    message
  }
}
`;

const CCMPage = () => {
    const { loading: ccmloading, error: ccmerror, data: ccmdata } = useQuery(GET_ALL_CCM_DETAILS);
    const [addCCM] = useMutation(ADD_CCM, {
        refetchQueries: [{ query: GET_ALL_CCM_DETAILS }], // Refetch the CCM list after adding
    });

    const [assignZSM] = useMutation(ASSIGN_CCM_TO_ZSM, {
        refetchQueries: [{ query: GET_ALL_CCM_DETAILS }], // Refetch the CCM list after assigning ZSM
    });

    // State to control the dialog
    const [open, setOpen] = useState(false);
    const [newCCM, setNewCCM] = useState({
        name: "",
        employeeno: "",
        email: "",
        mobile: "",
    });
    const [searchQuery, setSearchQuery] = useState("");

    // Handle opening and closing of the dialog
    const handleClickOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        setNewCCM({ name: "", employeeno: "", email: "", mobile: "" }); // Clear form when closing
    };

    // Handle form submission for adding a new CCM
    const handleAddCCM = async () => {
        try {
            await addCCM({ variables: { ...newCCM } });
            handleClose();
        } catch (error) {
            console.error("Error adding CCM:", error);
        }
    };

    // Handle form field changes
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNewCCM({ ...newCCM, [e.target.name]: e.target.value });
    };

    // Assign ZSM to CCM
    const handleAssignZSM = async (ccmEmployeeNo: string) => {
        const zsmEmployeeNo = prompt("Enter ZSM Employee No:");
        if (zsmEmployeeNo) {
            try {
                const { data } = await assignZSM({ variables: { ccmEmployeeNo, zsmEmployeeNo } });
                alert(data.assignZSMToCCM.message);
            } catch (error) {
                console.error("Error assigning ZSM to CCM:", error);
            }
        }
    };

    // Handle search input change
    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(e.target.value);
    };
    // Filter ZSM data based on search query
    const filteredZSMs = ccmdata?.getAllCCMDetails.filter((zsm: { name: string; }) =>
        zsm.name.toLowerCase().includes(searchQuery.toLowerCase())
    ) || [];

    return (
        <Box
            justifyContent="flex-start"
            sx={{ backgroundColor: "#F6F5F4", padding: 2 }}
        >
            {/* Header Section */}
            <Grid container spacing={2} justifyContent="flex-start" sx={{ padding: 1 }}>
                <Grid item xs={4}>
                    <Typography sx={{ fontSize: 32, fontWeight: "semi-bold", textAlign: "left" }}>CCM</Typography>
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        label="Search CCM"
                        variant="outlined"
                        fullWidth
                        value={searchQuery}
                        onChange={handleSearchChange}
                    />
                </Grid>
                <Grid item xs={4} sx={{ textAlign: "right" }}>
                    <Button variant="contained" color="primary" onClick={handleClickOpen}>
                        Add New ZSM
                    </Button>
                </Grid>
            </Grid>

            {/* Table Section with Scroll */}
            <Box sx={{ maxHeight: '70vh', overflowY: 'auto' }}>
                {ccmloading ? (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            minHeight: '50vh', // Adjust height as needed
                        }}
                    >
                        <CircularProgress />
                    </Box>
                ) : ccmerror ? (
                    <Typography>Error loading data</Typography>
                ) : (
                    <ServiceTable employees={filteredZSMs} isCCM={false} />
                )}
            </Box>

            {/* Dialog for Adding New CCM */}
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Add New CCM</DialogTitle>
                <DialogContent>
                    <TextField
                        margin="dense"
                        label="Name"
                        name="name"
                        fullWidth
                        value={newCCM.name}
                        onChange={handleChange}
                    />
                    <TextField
                        margin="dense"
                        label="Employee No"
                        name="employeeno"
                        fullWidth
                        value={newCCM.employeeno}
                        onChange={handleChange}
                    />
                    <TextField
                        margin="dense"
                        label="Email"
                        name="email"
                        fullWidth
                        value={newCCM.email}
                        onChange={handleChange}
                    />
                    <TextField
                        margin="dense"
                        label="Mobile"
                        name="mobile"
                        fullWidth
                        value={newCCM.mobile}
                        onChange={handleChange}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="secondary">Cancel</Button>
                    <Button onClick={handleAddCCM} color="primary">Add CCM</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default CCMPage;
