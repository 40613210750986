import React from 'react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Box, Skeleton } from '@mui/material';
import theme from '../../theme';

interface DriveModeTableProps {
  driveMode: Record<string, number>; // Adjust the type according to your actual data structure
  liveDriveMode?: Record<string, number>; // Adjust the type according to your actual data structure
  loading?: boolean;
}

const DriveModeTable: React.FC<DriveModeTableProps> = ({ driveMode, liveDriveMode, loading }) => {
  const renderDot = (value: number) => (
    <Box
      sx={{
        width: 10,
        height: 10,
        borderRadius: '50%',
        backgroundColor: value === 1 ? 'blue' : theme.palette.grey4.main,
        display: 'inline-block',
        marginLeft: 1
      }}
    />
  );

  const formatTime = (minutes: number) => {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    return `${hours} hrs ${mins.toFixed()} mins`;
  };

  const calculatePercentage = (value: number, total: number) => {
    if (total === 0) return '0%';
    const percentage = (value / total) * 100;
    return `${percentage.toFixed(2)}%`;
  };

  const totalDriveModeTime = Object.values(driveMode).reduce((sum, current) => sum + current, 0);

  return (
    <Paper>
      <Typography variant="h6" component="div" sx={{ padding: 2 }}>
        Drive Mode
      </Typography>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Drive Mode</TableCell>
              <TableCell>Selected Time Value</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(driveMode).map((key) => (
              <TableRow key={key}>
                <TableCell sx={{ fontWeight: 'bold' }}>
                  {loading ? <Skeleton variant="text" width="40%" /> : key}
                  {loading ? null : (liveDriveMode ? renderDot(liveDriveMode?.[key] ?? 0) : 'N/A')}
                </TableCell>
                <TableCell>{loading ? <Skeleton variant="text" width="40%" /> : calculatePercentage(driveMode[key], totalDriveModeTime)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default DriveModeTable;
