import React from "react";
import {
  VehicleFlashedInfo,
  DTC,
  Dataset,
  Ecu,
  FlashingDTCReport,
  MaterialInfo,
  Tool,
  ToolActivity,
  LiveParameters,
  PermittedActivities,
  AdjustMentParameters,
  SpeedLimiter,
} from "../../types";
import DownloadIcon from '@mui/icons-material/Download';

import { Button } from "@mui/material";
import DtcCodesColumn from "./DtcCodeColumn";
import { ColumnDef } from "@tanstack/react-table";

const handleDownload = (filePath: string) => {
  const downloadLink = document.createElement("a");
  downloadLink.href = filePath;
  downloadLink.download = filePath.split("/").pop() ?? "file"; // Get the file name from the file path
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};

export const vehicleFlashedInfoColumns: ColumnDef<VehicleFlashedInfo>[] = [
  {
    header: "Flashed On",
    accessorKey: "flashedOn",
    cell: ({ cell }:any) => {
      const dateValue = cell.getValue() as string;
      const date = new Date(dateValue);
      return (
        <p className="whitespace-nowrap">
          {date.toLocaleString("en-IN", {
            year: "numeric",
            month: "short",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            second: "numeric", // Include seconds in the format
          })}
        </p>
      );
    },
  },
  {
    header: "Dealer / Employee ID",
    accessorKey: "dealerAssignedId",
    cell: ({ row }:any) => {
      const dealerId = row.original?.dealerAssignedId;
      const employeeId = row.original?.employeeAssignedId;
      return <p className="whitespace-nowrap">{`${dealerId ? dealerId : employeeId ? employeeId : "NA"}`}</p>;
    },
  },
  {
    header: "VIN No",
    accessorKey: "vinAfterFlashing",
  },
  {
    header: "ECU Name",
    accessorKey: "ecu_name",
    cell: ({ cell }:any) => {
      const ecu_name = cell.getValue();
      return ecu_name ? ecu_name : "NA";
    },
  },
  {
    header: "Old Dataset Name",
    accessorKey: "oldDatasetName",
    cell: ({ cell }:any) => {
      const oldDatasetName = cell.getValue();
      return oldDatasetName ? oldDatasetName : "NA";
    },
  },
  {
    header: "New Dataset Name",
    accessorKey: "newDatasetName",
    cell: ({ cell }:any) => {
      const newDatasetName = cell.getValue();
      return newDatasetName ? newDatasetName : "NA";
    },
  },
  {
    header: "Flashing Status",
    accessorKey: "flashingStatus",
  },

  {
    header: "Tool Serial No",
    accessorKey: "toolSerialNumber",
    cell: ({ cell }:any) => {
      const toolSerialNumber = cell.getValue();
      return <p className="whitespace-nowrap">{`${toolSerialNumber ? toolSerialNumber : "NA"}`}</p>;
    },
  },
];

export const dtcColumns: ColumnDef<DTC>[] = [
  {
    header: "Inserted Date",
    accessorKey: "createdOn",
    cell: ({ cell }:any) => {
      const dateValue = cell.getValue() as string; // Ensure the type is correctly string
      const date = new Date(dateValue);
      return (
        <p className="whitespace-nowrap">
          {date.toLocaleString("en-IN", {
            year: "numeric",
            month: "short",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            second: "numeric", 
          })}
        </p>
      );
    },
  },
  {
    header: "VIN Number",
    accessorKey: "vinNo",
    cell: ({ row }:any) => {
      const vinNo = row.original.vinNo;
      const isValidVinNo = /^[A-Z0-9]{17}$/.test(vinNo); // Ensure the regex is correct.
      return <p>{isValidVinNo ? vinNo : "NA"}</p>; // Make sure <p> is wrapped correctly in JSX
    },
  },
  {
    header: "DTC Codes",
    accessorKey: "dtcCodes",
    cell: ({ row }:any) => {
      return (
        <div className="w-[58rem] overflow-hidden">
          <DtcCodesColumn row={row} />
        </div>
      );
    },
  },
  {
    header: "ECU Name",
    accessorKey: "ecu_name",
  },

  { header: "City", accessorKey: "city" },
  { header: "State", accessorKey: "state" },
  { header: "Zone", accessorKey: "zone" },
  { header: "Location", accessorKey: "location" },
];

export const datasetColumns: ColumnDef<Dataset>[] = [
  {
    header: "Released On",
    accessorKey: "releasedOn",
    cell: ({ cell }:any) => {
      const dateValue = cell.getValue() as string;
      const date = new Date(dateValue);
      return (
        <p className="whitespace-nowrap">
          {date.toLocaleString("en-IN", {
            year: "numeric",
            month: "short",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            second: "numeric",
          })}
        </p>
      );
    },
  },
  {
    header: "Dataset ID",
    accessorKey: "datasetId",
  },
  {
    header: "Dataset Name",
    accessorKey: "datasetName",
  },
  {
    header: "Dataset Type",
    accessorKey: "datasetType",
  },
  {
    header: "ECU Type",
    accessorKey: "ecuType",
  },
  {
    header: "Model",
    accessorKey: "model",
  },
  {
    header: "Variant",
    accessorKey: "variant",
  },
  {
    header: "Stage",
    accessorKey: "stage",
  },
  {
    header: "Files",
    accessorKey: "fileName",
    cell: ({ row }:any) => (
      <div className="flex gap-2 overflow-x-auto whitespace-nowrap">
        {row.original.fileName.split(",").map((file: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined, index: React.Key | null | undefined) => (
          <button
            key={index}
            className="px-3 py-1 bg-gray-100 text-gray-800 text-sm rounded-sm cursor-default border border-gray-300 hover:bg-gray-200 transition-colors"
          >
            {file}
          </button>
        ))}
      </div>
    ),
  },
  {
    header: "Created By",
    accessorKey: "createdBy",
  },

  {
    header: "Active",
    accessorKey: "isActive",
    cell: ({ row }:any) => (row.original.isActive ? "Yes" : "No"),
  },
  {
    header: "ECU Name",
    accessorKey: "ecu_name",
  },
];

export const ecuColumns: ColumnDef<Ecu>[] = [
  {
    header: "Created On",
    accessorKey: "createdOn",
    cell: ({ cell }:any) => {
      const dateValue = cell.getValue() as string;
      const date = new Date(dateValue);
      return (
        <p className="whitespace-nowrap">
          {date.toLocaleString("en-IN", {
            year: "numeric",
            month: "short",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            second: "numeric",
          })}
        </p>
      );
    },
  },
  {
    header: "ECU Name",
    accessorKey: "ecuName",
  },
  {
    header: "Created By",
    accessorKey: "createdBy",
  },
];

export const flashingInformationColumns: ColumnDef<FlashingDTCReport>[] = [
  {
    header: "ID",
    accessorKey: "id",
  },
  {
    header: "VIN",
    accessorKey: "vin",
  },
  {
    header: "Flashed On",
    accessorKey: "flashedOn",
  },
  {
    header: "ECU",
    accessorKey: "ecu",
  },
  {
    header: "Flashing Status",
    accessorKey: "flashingStatus",
  },
  {
    header: "Tact Time",
    accessorKey: "tactTime",
  },
  {
    header: "DTC",
    accessorKey: "dtc",
  },
  {
    header: "Dealer Serial No",
    accessorKey: "dealerSerialNo",
  },
  {
    header: "Dataset ID",
    accessorKey: "datasetId",
  },
];

export const materialInfoColumns: ColumnDef<MaterialInfo>[] = [
  {
    header: "Material ID",
    accessorKey: "materialId",
  },
  {
    header: "Billing Date",
    accessorKey: "billingDate",
  },
  {
    header: "Material Code",
    accessorKey: "materialCode",
  },
  {
    header: "Chasis No",
    accessorKey: "chasisNo",
  },
  {
    header: "Engine No",
    accessorKey: "engineNo",
  },
  {
    header: "Customer",
    accessorKey: "customer",
  },
  {
    header: "Name",
    accessorKey: "name",
  },
  {
    header: "Region",
    accessorKey: "region",
  },
  {
    header: "District",
    accessorKey: "district",
  },
  {
    header: "State",
    accessorKey: "state",
  },
  {
    header: "Material Description",
    accessorKey: "materialDesc",
  },
  {
    header: "Added On",
    accessorKey: "addedOn",
  },
];

export const toolColumns: ColumnDef<Tool>[] = [
  {
    header: "Created On",
    accessorKey: "addedOn",
    cell: ({ cell }:any) => {
      const dateValue = cell.getValue() as string;
      const date = new Date(dateValue);
      return (
        <p className="whitespace-nowrap">
          {date.toLocaleString("en-IN", {
            year: "numeric",
            month: "short",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            second: "numeric",
          })}
        </p>
      );
    },
  },
  {
    header: "Tool Serial",
    accessorKey: "toolSerial",
  },
  {
    header: "Permitted Activities",
    accessorKey: "permittedActivities",
    cell: ({ row }:any) => {
      const activities = row.original.permittedActivities as PermittedActivities | null;

      // Check if permittedActivities exists and is not null
      if (activities) {
        // Get the keys of the permitted activities and display them as a comma-separated string
        const permitted = (Object.keys(activities) as Array<keyof PermittedActivities>)
          .filter((activity) => activities[activity]) // Only show activities that are true
          .join(", ");

        return permitted || "None"; // Return "None" if no activities are permitted
      }

      return "None";
    },
  },
  {
    header: "Active",
    accessorKey: "isActive",
    cell: ({ row }:any) => (row.original.isActive ? "Yes" : "No"),
  },
  {
    header: "Dealer ID",
    accessorKey: "dealerAssignedId",
    cell: ({ cell }:any) => {
      const dealerId = cell.getValue();
      return dealerId ? dealerId : "NA";
    },
  },
];

export const toolActivityColumns: ColumnDef<ToolActivity>[] = [
  {
    header: "Activity ID",
    accessorKey: "id",
  },
  {
    header: "Activity",
    accessorKey: "activity",
  },
  {
    header: "Logged Time",
    accessorKey: "loggedTime",
  },
  {
    header: "Tool ID",
    accessorKey: "toolId",
  },
];

export const liveParametersColumns: ColumnDef<LiveParameters>[] = [
  {
    header: "Inserted Date",
    accessorKey: "createdOn",
    cell: ({ cell }:any) => {
      const dateValue = cell.getValue() as string;
      const date = new Date(dateValue);
      return (
        <p className="whitespace-nowrap">
          {date.toLocaleString("en-IN", {
            year: "numeric",
            month: "short",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            second: "numeric",
          })}
        </p>
      );
    },
  },
  {
    header: "Dealer / Employee ID",
    accessorKey: "dealerAssignedId",
    cell: ({ row }:any) => {
      // Dynamically check for either dealerAssignedId or employeeAssignedId
      const dealerAssignedId = row.original?.dealerAssignedId;
      const employeeAssignedId = row.original?.employeeAssignedId;

      // Return the ID in a paragraph with the specified format
      return <p className="whitespace-nowrap">{`${dealerAssignedId ? dealerAssignedId : employeeAssignedId ? employeeAssignedId : "NA"}`}</p>;
    },
  },
  {
    header: "VIN Number",
    accessorKey: "vinNumber",
    cell: ({ row }:any) => {
      const vinNo = row.original.vinNumber || "";
      const isValidVinNo = /^[A-Z0-9]{17}$/.test(vinNo);
      return <p>{isValidVinNo ? vinNo : "NA"}</p>;
    },
  },
  { header: "ECU Name", accessorKey: "ecu_Name" },
  { header: "Tool Serial No.", accessorKey: "toolSerial_Number" },
  {
    header: "Action",
    id: "actions",
    cell: ({ row }:any) => {
      const handleDownload = (url: string, isPdf: boolean) => {
        const anchor = document.createElement("a");
        anchor.href = url;
        if (isPdf) {
          anchor.target = "_blank";
        } else {
          anchor.download = "";
        }
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
      };
      const liveParams = row.original;

      return (
        <div className="flex flex-row gap-x-1">
          {row.original.livedata_download_url && (
            <Button
              onClick={() => handleDownload(row.original.livedata_download_url, false)}>
              CSV
              <DownloadIcon className="h-4 w-4 ml-1 text-primary" />
            </Button>
          )}
          {row.original.graph_download_url && (
            <Button onClick={() => handleDownload(row.original.graph_download_url, true)}>
              Graph
              <DownloadIcon className="h-4 w-4 ml-1 text-primary" />
            </Button>
          )}
        </div>
      );
    },
  },
];

export const adjustmentParamsColumns: ColumnDef<AdjustMentParameters>[] = [
  {
    header: "Created On",
    accessorKey: "createdOn",
    cell: ({ cell }:any) => {
      const dateValue = cell.getValue() as string;
      const date = new Date(dateValue);
      return (
        <p className="whitespace-nowrap">
          {date.toLocaleString("en-IN", {
            year: "numeric",
            month: "short",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            second: "numeric",
          })}
        </p>
      );
    },
  },
  {
    header: "Dealer / Employee ID",
    accessorKey: "dealerAssignedId",
    cell: ({ row }:any) => {
      const dealerAssignedId = row.original?.dealerAssignedId;
      const employeeAssignedId = row.original?.employeeAssignedId;
      return <p className="whitespace-nowrap">{`${dealerAssignedId ? dealerAssignedId : employeeAssignedId ? employeeAssignedId : "NA"}`}</p>;
    },
  },
  {
    header: "VIN Number",
    accessorKey: "vinNo",
    cell: ({ row }:any) => {
      const vinNo = row.original.vinNo;
      const isValidVinNo = /^[A-Z0-9]{17}$/.test(vinNo);
      return <p>{isValidVinNo ? vinNo : "NA"}</p>;
    },
  },
  {
    header: "Ecu Name",
    accessorKey: "ecuName",
  },
  {
    header: "Parameter Name",
    accessorKey: "parameterName",
  },
  {
    header: "Value Before Adjustment",
    accessorKey: "valueBeforeAdjustment",
  },
  {
    header: "Value After Adjustment",
    accessorKey: "valueAfterAdjustment",
  },
];


export const SpeedLimiterColumns: ColumnDef<SpeedLimiter>[] = [
  {
    header: "Request Date",
    accessorKey: "requestedOn",
    cell: ({ cell }:any) => {
      const dateValue = cell.getValue() as string;
      const date = new Date(dateValue);
      return (
        <p className="whitespace-nowrap">
          {date.toLocaleString("en-IN", {
            year: "numeric",
            month: "short",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            second: "numeric",
          })}
        </p>
      );
    },
  },
  {
    header: "Dealer ID",
    accessorKey: "dealerAssignedId",
  },
  {
    header: "Dealer Name",
    accessorKey: "dealerName",
  },
  {
    header: "VIN NO",
    accessorKey: "vin",
  },
  {
    header: "Status",
    accessorKey: "requestStatus",
    cell: ({ row }:any) => {
      const status = row.original.requestStatus;
      switch (status) {
        case 1:
          return "Requested by User";
        case 2:
          return "Set by Admin";
        case 3:
          return "Confirmed Success by User";
        case 4:
          return "Confirmed Failure by User";
        default:
          return "Unknown Status";
      }
    },
  },
  {
    header: "Vehicle Speed KM / H",
    accessorKey: "vehicleSpeed",
    cell: ({ row }:any) => {
      const speed = row.original.vehicleSpeed;
      return speed === -1 ? "Requested to set max speed" : `${speed}`;
    },
  },

  {
    header: "Write Date",
    accessorKey: "writtenOn",
    cell: ({ cell }:any) => {
      const dateValue = cell.getValue() as string;
      const date = new Date(dateValue);
      return (
        <p className="whitespace-nowrap">
          {date.toLocaleString("en-IN", {
            year: "numeric",
            month: "short",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            second: "numeric",
          })}
        </p>
      );
    },
  },

  {
    header: "OEM ID",
    accessorKey: "oemId",
  },
];
