import React, { useState } from "react";
import { Box, Button, CircularProgress, Grid, Typography, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import ServiceTable from "./ServiceTable";
import { gql, useQuery, useMutation } from "@apollo/client";

// Query to get all ZSM details
const GET_ALL_ZSM_DETAILS = gql`
query GetAllZSMDetails {
  getAllZSMDetails {
    id
    name
    employeeno
    email
    mobile
    createdat
    updatedat
  }
}
`;

// Mutation to add a new ZSM
const ADD_ZSM = gql`
mutation AddZSM($name: String!, $employeeno: String!, $email: String!, $mobile: String!) {
  addZSMDetails(name: $name, employeeno: $employeeno, email: $email, mobile: $mobile) {
    status
    message
  }
}
`;

const ASSIGN_ZSM_TO_CCM = gql`
  mutation AssignZSMToCCM($ccmEmployeeNo: String!, $zsmEmployeeNo: String!) {
    assignZSMToCCM(ccmEmployeeNo: $ccmEmployeeNo, zsmEmployeeNo: $zsmEmployeeNo) {
      status
      message
    }
  }
`;


const ZSMPage = () => {
  const { loading: zsmloading, error: zsmerror, data: zsmdata } = useQuery(GET_ALL_ZSM_DETAILS);
  const [assignZSMToCCM] = useMutation(ASSIGN_ZSM_TO_CCM);
  const [addZSM] = useMutation(ADD_ZSM, {
    refetchQueries: [{ query: GET_ALL_ZSM_DETAILS }] // Refetch the ZSM list after adding
  });

  // State to control the dialog and form
  const [open, setOpen] = useState(false);
  const [selectZSMDialogOpen, setSelectZSMDialogOpen] = useState(false); // For ZSM assignment
  const [newZSM, setNewZSM] = useState({
    name: "",
    employeeno: "",
    email: "",
    mobile: ""
  });
  const [selectedCCMEmployeeNo, setSelectedCCMEmployeeNo] = useState(""); // For storing selected CCM
  const [dialogOpen, setDialogOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  // Open/close dialog for adding new ZSM
  const handleClickOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setNewZSM({ name: "", employeeno: "", email: "", mobile: "" });
  };

  // Open dialog to select ZSM for a CCM
  const handleAssignZSM = (ccmEmployeeNo: string) => {
    setSelectedCCMEmployeeNo(ccmEmployeeNo);
    setSelectZSMDialogOpen(true);
  };

  // Handle form submission for adding a new ZSM
  const handleAddZSM = async () => {
    try {
      await addZSM({ variables: { ...newZSM } });
      handleClose();
    } catch (error) {
      console.error("Error adding ZSM:", error);
    }
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setSelectedCCMEmployeeNo("");
  };

  // Handle ZSM selection for a CCM
  const handleSelectZSM = async (zsmEmployeeNo: string) => {
    try {
      await assignZSMToCCM({
        variables: {
          ccmEmployeeNo: selectedCCMEmployeeNo,
          zsmEmployeeNo: zsmEmployeeNo
        }
      });
      setSelectZSMDialogOpen(false);
    } catch (error) {
      console.error("Error assigning ZSM:", error);
    }
  };

  // Handle form field changes
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewZSM({ ...newZSM, [e.target.name]: e.target.value });
  };

      // Handle search input change
      const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(e.target.value);
      };
  
      // Filter ZSM data based on search query
      const filteredZSMs = zsmdata?.getAllZSMDetails.filter((zsm: { name: string; }) =>
        zsm.name.toLowerCase().includes(searchQuery.toLowerCase())
      ) || [];

  return (
    <Box justifyContent="flex-start" sx={{ backgroundColor: "#F6F5F4", padding: 2 }}>
      <Grid container spacing={2} justifyContent="flex-start" sx={{ padding: 1 }}>
        <Grid item xs={4}>
          <Typography sx={{ fontSize: 32, fontWeight: "semi-bold", textAlign: "left" }}>ZSM</Typography>
        </Grid>
        <Grid item xs={4}>
          <TextField
            label="Search ZSM"
            variant="outlined"
            fullWidth
            value={searchQuery}
            onChange={handleSearchChange}
          />
        </Grid>
        <Grid item xs={4} sx={{ textAlign: "right" }}>
          <Button variant="contained" color="primary" onClick={handleClickOpen}>
            Add New ZSM
          </Button>
        </Grid>
      </Grid>
      <Box sx={{ maxHeight: '70vh', overflowY: 'auto' }}>
        {zsmloading ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              minHeight: '50vh', // Adjust height as needed
            }}
          >
            <CircularProgress />
          </Box>
        ) : zsmerror ? (
          <Typography>Error loading data</Typography>
        ) : (
          <ServiceTable employees={filteredZSMs} isCCM={false} />
        )}
      </Box>

      {/* Dialog for Adding New ZSM */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add New ZSM</DialogTitle>
        <DialogContent>
          <TextField margin="dense" label="Name" name="name" fullWidth value={newZSM.name} onChange={handleChange} />
          <TextField margin="dense" label="Employee No" name="employeeno" fullWidth value={newZSM.employeeno} onChange={handleChange} />
          <TextField margin="dense" label="Email" name="email" fullWidth value={newZSM.email} onChange={handleChange} />
          <TextField margin="dense" label="Mobile" name="mobile" fullWidth value={newZSM.mobile} onChange={handleChange} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">Cancel</Button>
          <Button onClick={handleAddZSM} color="primary">Add ZSM</Button>
        </DialogActions>
      </Dialog>

      {/* Dialog for Selecting ZSM */}
      <Dialog open={dialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>Assign ZSM</DialogTitle>
        <DialogContent>
          {/* Implement ZSM selection logic here */}
          <Typography>Assigning ZSM to Employee No: {selectedCCMEmployeeNo}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleCloseDialog} color="primary">
            Confirm Assignment
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ZSMPage;


