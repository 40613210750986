import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
  Paper,
  Typography,
  Grid,
  Skeleton,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Chip,
} from "@mui/material";
import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer, CartesianGrid, Legend } from "recharts";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import { DPFParams } from "../../types";
import dayjs from "dayjs";
import theme from "../../theme";

interface DPFParamsTableProps {
  dpfHistory: DPFParams; // Expecting an array of objects
  liveDpf: DPFParams | null; // Expecting an object or null
  loading?: boolean;
}

const DPFParamsTable: React.FC<DPFParamsTableProps> = ({ liveDpf, dpfHistory, loading }) => {
  const [isGraphOpen, setGraphOpen] = useState(false);
  const [xAxisKey, setXAxisKey] = useState('ts'); // Default X-axis is timestamp
  const [selectedYAxisKeys, setSelectedYAxisKeys] = useState(['sm']); // Default Y-axis is soot mass

  const yAxisOptions = [
    { key: 'sm', name: 'Soot Mass (gms)', color: '#8884d8' },
    { key: 'srgn', name: 'Successful Regenerations', color: '#82ca9d' },
    { key: 'rgnd', name: 'Regeneration Demands', color: '#ff7300' },
  ];

  const xAxisOptions = [
    { key: 'ts', name: 'Timestamp' },
    { key: 'odo', name: 'Odometer' },
    { key: 'hrm', name: 'Hourmeter' },
  ];

  const handleXAxisChange = (event: { target: { value: React.SetStateAction<string>; }; }) => {
    setXAxisKey(event.target.value);
  };

  const handleYAxisToggle = (key: string) => {
    setSelectedYAxisKeys((prev) =>
      prev.includes(key) ? prev.filter((y) => y !== key) : [...prev, key]
    );
  };

  type FormattedData = {
    ts: string;
    sm: number;
    odo: number;
    hrm: number;
    srgn: number;
    rgnd: number;
    [key: string]: string | number;
  };

  const formattedData: FormattedData[] =
    Array.isArray(dpfHistory.dpf)
      ? dpfHistory.dpf.map((entry) => ({
        ...entry,
        ts: dayjs.unix(entry.ts).format('YYYY-MM-DD HH:mm:ss'), // Convert timestamp to human-readable date
      }))
      : [];

  const handleGraphOpen = () => setGraphOpen(true);
  const handleGraphClose = () => setGraphOpen(false);

  return (
    <Box sx={{ mt: 2 }}>
      <Paper elevation={3} sx={{ width: "100%", mb: 1, p: 2, position: "relative" }}>
        <Typography variant="h6" sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          DPF Parameters
          <IconButton onClick={handleGraphOpen} size="small" title="Show Graph">
            <ShowChartIcon />
          </IconButton>
        </Typography>
        <Grid container spacing={2}>
          {/* Table for live and historic data */}
          <Grid item xs={12}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>DPF Parameters</TableCell>
                  <TableCell align="right">Lifetime Value</TableCell>
                  <TableCell align="right">Selected Time Value</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell sx={{ fontWeight: "bold" }}>Soot Mass</TableCell>
                  <TableCell align="right">
                    {loading ? <Skeleton variant="text" width="40%" /> : `${liveDpf?.sootMass.toFixed(2)} gms`}
                  </TableCell>
                  <TableCell align="right">{loading ? <Skeleton variant="text" width="40%" /> : "N/A"}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ fontWeight: "bold" }}>Regeneration Demands</TableCell>
                  <TableCell align="right">
                    {loading ? <Skeleton variant="text" width="40%" /> : liveDpf?.regenerationDemands}
                  </TableCell>
                  <TableCell align="right">
                    {loading ? <Skeleton variant="text" width="40%" /> : dpfHistory.regenerationDemands}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ fontWeight: "bold" }}>Successful Regenerations</TableCell>
                  <TableCell align="right">
                    {loading ? <Skeleton variant="text" width="40%" /> : liveDpf?.successfulRegenerations}
                  </TableCell>
                  <TableCell align="right">
                    {loading ? <Skeleton variant="text" width="40%" /> : dpfHistory.successfulRegenerations}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ fontWeight: "bold" }}>Distance Since Last Successful Regeneration</TableCell>
                  <TableCell align="right">
                    {loading ? (
                      <Skeleton variant="text" width="40%" />
                    ) : liveDpf ? `${(liveDpf.lastSuccessfulRegeneration / 1000).toFixed(2)} Kms` : "N/A"}
                  </TableCell>
                  <TableCell align="right">{loading ? <Skeleton variant="text" width="40%" /> : "N/A"}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </Paper>

      {/* Dialog for graph */}
      <Dialog open={isGraphOpen} onClose={handleGraphClose} fullWidth maxWidth="xl" sx={{  backgroundColor: "#F6F5F4", }}>
        <DialogTitle sx={{  backgroundColor: "#F6F5F4", }}>DPF History Graph</DialogTitle>
        <DialogContent sx={{  backgroundColor: "#F6F5F4", }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, backgroundColor: "#F6F5F4",}}>
            {/* Top Section: Summary Card and Controls */}
            <Grid container spacing={2} alignItems="stretch">
              {/* Summary Card */}
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    p: 3,
                    backgroundColor: "#fff",
                    boxShadow: 2,
                    borderRadius: 2,
                    height: '100%', // Match height with adjacent column
                  }}
                >
                  <Typography variant="h6" gutterBottom>
                    Summary
                  </Typography>
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <Typography variant="body2">
                        <strong>Start:</strong>
                      </Typography>
                      <Typography variant="body2">
                        {formattedData[formattedData.length - 1]?.[xAxisKey] || 'N/A'}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2">
                        <strong>End:</strong>
                      </Typography>
                      <Typography variant="body2">
                        {formattedData[0]?.[xAxisKey] || 'N/A'}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2">
                        <strong>Last Soot Mass:</strong>
                      </Typography>
                      <Typography variant="body2">
                        {formattedData[0]?.sm || 'N/A'}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2">
                        <strong>Regen Demand:</strong>
                      </Typography>
                      <Typography variant="body2">
                        {formattedData[0]?.rgnd || 'N/A'}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2">
                        <strong>Successful Regen:</strong>
                      </Typography>
                      <Typography variant="body2">
                        {formattedData[0]?.srgn || 'N/A'}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2">
                        <strong>Total Data Points:</strong>
                      </Typography>
                      <Typography variant="body2">{formattedData.length || '0'}</Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              {/* X-Axis and Y-Axis Selectors */}
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    p: 3,
                    backgroundColor: '#fff',
                    boxShadow: 2,
                    borderRadius: 2,
                    height: '100%', // Match height with adjacent column

                  }}
                >
                  <Box >
                    <Typography variant="body1" gutterBottom >
                      X-Axis
                    </Typography>
                    <FormControl variant="outlined" size="small" fullWidth sx={{mt: 2}}>
                      <InputLabel>X-Axis</InputLabel>
                      <Select value={xAxisKey} onChange={handleXAxisChange} label="X-Axis">
                        {xAxisOptions.map((option) => (
                          <MenuItem key={option.key} value={option.key}>
                            {option.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                  <Box
                    mt={2}
                    display="flex"
                    flexDirection="column"
                    gap={2}
                  >
                    <Typography variant="body1" gutterBottom>
                      Y-Axis
                    </Typography>
                    <Box
                      display="flex"
                      flexWrap="wrap"
                      gap={1}
                    >
                      {yAxisOptions.map((option) => (
                        <Chip
                          key={option.key}
                          label={option.name}
                          sx={{
                            backgroundColor: selectedYAxisKeys.includes(option.key) ? option.color : '#f0f0f0',
                            color: selectedYAxisKeys.includes(option.key) ? '#fff' : '#000',
                            '&:hover': {
                              backgroundColor: option.color,
                              opacity: 0.8,
                            },
                          }}
                          onClick={() => handleYAxisToggle(option.key)}
                        />
                      ))}
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
            {/* Line Chart */}
            <Box
              sx={{
                height: '400px',
                boxShadow: 2,
                borderRadius: 2,
                p: 2,
                backgroundColor: '#fff',
              }}
            >
              <ResponsiveContainer width="100%" height="100%">
                <LineChart data={(formattedData || []).slice().reverse()}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey={xAxisKey} name="X-Axis" />
                  <YAxis />
                  <Tooltip
                    formatter={(value, name) => [`${value}`, name]}
                    labelFormatter={(label) => `X-Axis: ${label}`}
                  />
                  <Legend />
                  {selectedYAxisKeys.map((key) => {
                    const option = yAxisOptions.find((option) => option.key === key);
                    return (
                      <Line
                        key={key}
                        type="monotone"
                        dataKey={key}
                        name={option!.name}
                        stroke={option!.color}
                        activeDot={{ r: 5 }}
                      />
                    );
                  })}
                </LineChart>
              </ResponsiveContainer>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default DPFParamsTable;
