import { Box, Tab, Tabs, Typography } from "@mui/material";
import { useState } from "react";
import { styled } from "@mui/material/styles";
import { TabPanel, TabContext } from "@mui/lab";
import theme from "../../theme";
import VehicleDiagnosticsPage from "../../components/VehicleDiagnostics/VehicleDiagnosticPage";
import ToolActivity from "../ToolActivity/ToolActivity";



const VehicleDiagnostics = () => {
  const CustomTab = styled(Tab)(({ theme }) => ({
    borderBottom: "3.5px solid #e8e8e8",
    "&.MuiButtonBase-root": {
      textTransform: "none",
      fontSize: "1.2rem",
      fontWeight: "600",
      borderBottom: "3.5px solid #e8e8e8",
    },
    "&.MuiTab-wrapper": {
      textTransform: "none",
      fontSize: "1rem",
      fontWeight: "bold",
      color: theme.palette.black.main,
    },
    "&.Mui-selected": {
      color: theme.palette.black.main,
    },
    "&:focus": {
      opacity: 1,
      color: theme.palette.black.main,
    },
  }));

  const CustomTabPanel = styled(TabPanel)(({ theme }) => ({
    padding: 0,
    width: "100%",
    height: "100%",
  }));

  const [selectedTab, setSelectedTab] = useState("VD");

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setSelectedTab(newValue);
  };

  return (
    <Box sx={{ backgroundColor: "#F6F5F4", padding: 2, height: "100vh", display: 'flex', flexDirection: 'column' }}>
      {/* Header Section */}
      <Box sx={{ marginBottom: 2 }}>
        <Typography sx={{ fontSize: 32, fontWeight: "semi-bold", textAlign: "left" }}>
          Vehicle Diagnostics Analytics
        </Typography>
      </Box>

      {/* Tabs and Content Section */}
      <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', height: '100%' }}>
        <TabContext value={selectedTab}>
          <Box>
            <Tabs
              value={selectedTab}
              onChange={handleTabChange}
              aria-label="Hierarchy-tabs"
              TabIndicatorProps={{
                style: {
                  backgroundColor: theme.palette.black.main,
                  height: "3.5px",
                },
              }}
            >
              <CustomTab label="Vehicle Diagnostics" value="VD" />
              <CustomTab label="Tool Activity" value="TA" />
            </Tabs>
          </Box>

          {/* Tab Panels */}
          <Box sx={{ flexGrow: 1, paddingTop: 2, height: 'calc(100vh - 128px)', overflow: 'auto' }}>
            <CustomTabPanel value="VD">
              <VehicleDiagnosticsPage />
            </CustomTabPanel>

            <CustomTabPanel value="TA">
              <ToolActivity />
            </CustomTabPanel>
          </Box>
        </TabContext>
      </Box>
    </Box>
  );
};

export default VehicleDiagnostics;


