import CheckIcon from "@mui/icons-material/Check";
import { Button, ButtonProps, CircularProgress, CircularProgressProps, Box } from "@mui/material";

interface LoadingButtonProps extends ButtonProps {
  loading: boolean;
  done: boolean;
  progressColor: CircularProgressProps["color"];
  progressSize: CircularProgressProps["size"];
}

const LoadingButton = (props: LoadingButtonProps) => {
  const { loading, done, progressColor, progressSize, ...other } = props;
  if (!progressColor) {
    console.log("no color specified");
  }

  if (done) {
    return (
      <Button {...other}>
        <CheckIcon />
      </Button>
    );
  } else if (loading) {
    return (
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress color={progressColor} size={progressSize} />
      </Box>
    );
  } else {
    return <Button {...other} />;
  }
};

LoadingButton.defaultProps = {
  loading: false,
  done: false,
  progressColor: "primary",
  progressSize: "1.5rem",
};

// LoadingButton.propTypes = {
//     // classes: PropTypes.object.isRequired,
//     loading: PropTypes.bool,
//     done: PropTypes.bool,
//     progressColor: PropTypes.string,
// };

export default LoadingButton;
