import React, { useState } from "react";
import { Box, Tabs, Tab, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { TabPanel, TabContext } from "@mui/lab";
import Tree from "react-d3-tree";
import theme from "../../theme";
import ZSMPage from "../../components/Hierarchy/ZSMPage";
import CCMPage from "../../components/Hierarchy/CCMPage";
import DealerPage from "../../components/Hierarchy/Dealerpage";


const Hierarchy = () => {
  const CustomTab = styled(Tab)(({ theme }) => ({
    borderBottom: "3.5px solid #e8e8e8",
    "&.MuiButtonBase-root": {
      textTransform: "none",
      fontSize: "1.2rem",
      fontWeight: "600",
      borderBottom: "3.5px solid #e8e8e8",
    },
    "&.MuiTab-wrapper": {
      textTransform: "none",
      fontSize: "1rem",
      fontWeight: "bold",
      color: theme.palette.black.main,
    },
    "&.Mui-selected": {
      color: theme.palette.black.main,
    },
    "&:focus": {
      opacity: 1,
      color: theme.palette.black.main,
    },
  }));

  const CustomTabPanel = styled(TabPanel)(({ theme }) => ({
    padding: 0,
    width: "100%",
    height: "100%",
  }));

  const [selectedTab, setSelectedTab] = useState("ZSM");

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setSelectedTab(newValue);
  };

  const orgChart = {
    name: "CEO",
    children: [
      {
        name: "Manager",
        attributes: {
          department: "Production",
        },
        children: [
          {
            name: "Foreman",
            attributes: {
              department: "Fabrication",
            },
            children: [
              {
                name: "Worker",
              },
            ],
          },
          {
            name: "Foreman",
            attributes: {
              department: "Assembly",
            },
            children: [
              {
                name: "Worker",
              },
            ],
          },
        ],
      },
    ],
  };


  return (
    <Box sx={{ backgroundColor: "#F6F5F4", padding: 2, height: "100vh", display: 'flex', flexDirection: 'column' }}>
      {/* Header Section */}
      <Box sx={{ marginBottom: 2 }}>
        <Typography sx={{ fontSize: 32, fontWeight: "semi-bold", textAlign: "left" }}>
          Hierarchy Data
        </Typography>
      </Box>

      {/* Tabs and Content Section */}
      <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', height: '100%' }}>
        <TabContext value={selectedTab}>
          <Box>
            <Tabs
              value={selectedTab}
              onChange={handleTabChange}
              aria-label="SML Network tabs"
              TabIndicatorProps={{
                style: {
                  backgroundColor: theme.palette.black.main,
                  height: "3.5px",
                },
              }}
            >
              {/* <CustomTab label="Overview" value="OVERVIEW" /> */}
              <CustomTab label="ZSM" value="ZSM" />
              <CustomTab label="CCM" value="CCM" />
              <CustomTab label="Dealers" value="DEALERS" />
              {/* <CustomTab label="VIN" value="VIN" /> */}
            </Tabs>
          </Box>

          {/* Tab Panels */}
          <Box sx={{ flexGrow: 1, paddingTop: 2, height: 'calc(100vh - 128px)', overflow: 'auto' }}>
            {/* <CustomTabPanel value="OVERVIEW">
              <Box sx={{ width: "100%", height: "100%" }}>
                <Tree data={orgChart} />
              </Box>
            </CustomTabPanel> */}

            <CustomTabPanel value="ZSM">
              <ZSMPage />
            </CustomTabPanel>

            <CustomTabPanel value="CCM">
              <CCMPage/>
            </CustomTabPanel>

            <CustomTabPanel value="DEALERS">
              <DealerPage/>
            </CustomTabPanel>

            {/* <CustomTabPanel value="VIN">
              <Typography>VIN Content</Typography>
            </CustomTabPanel> */}
          </Box>
        </TabContext>
      </Box>
    </Box>
  );
};

export default Hierarchy;
