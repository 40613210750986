import React from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { FormControl } from "@mui/material";
import dayjs from "dayjs";

interface MuiDatePickerProps {
  dateChange: (timestamp: number) => void;
  value: number;
  maxDate?: number;
  minDate?: number;
  disabled?: boolean;
  showTime?: boolean; // Add a boolean to toggle time picker
}

const MuiDatePicker: React.FC<MuiDatePickerProps> = ({
  dateChange,
  value,
  maxDate,
  minDate,
  disabled,
  showTime = false, // Default to false if not provided
}) => {
  return (
    <div>
      <FormControl fullWidth>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          {showTime ? (
            <DateTimePicker
              disabled={disabled}
              defaultValue={dayjs(value * 1000)}
              maxDateTime={maxDate ? dayjs(maxDate * 1000) : undefined}
              minDateTime={minDate ? dayjs(minDate * 1000) : undefined}
              onAccept={(e: any) => {
                dateChange(Math.floor(new Date(e.$d).getTime() / 1000));
              }}
              closeOnSelect={false}
              format="DD/MM/YYYY hh:mm A"
            />
          ) : (
            <DesktopDatePicker
              disabled={disabled}
              defaultValue={dayjs(value * 1000)}
              maxDate={maxDate ? dayjs(maxDate * 1000) : undefined}
              minDate={minDate ? dayjs(minDate * 1000) : undefined}
              onAccept={(e: any) => {
                dateChange(Math.floor(new Date(e.$d).getTime() / 1000));
              }}
              format="DD/MM/YYYY"
            />
          )}
        </LocalizationProvider>
      </FormControl>
    </div>
  );
};

export default MuiDatePicker;
