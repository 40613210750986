import { Box, Grid, Typography, CircularProgress, Skeleton, Button, TextField, Autocomplete } from "@mui/material";
import TimePeriodSelector from "../../components/Miscellaneous/TimePeriodSelector";
import { useEffect, useState } from "react";
import { LiveVehicle } from "../../types";
import { enqueueSnackbar } from "notistack";
import { useFirstLoad } from "../../hooks/useSkeloton";
import { gql, useLazyQuery } from "@apollo/client";
import { VehicleSearch } from "../../components/ToolActivity/VehicleSearch";
import VehicleCard from "../../components/VehicleDiagnostics/VehicleInfoCard";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import { ColumnDef } from "@tanstack/react-table";
import theme from "../../theme";

// Query to fetch all vehicle live statuses
const GET_ALL_VEHICLE_LIVE_STATUS = gql`
  query GetAllVehicleLiveStatus {
    getAllVehicleLiveStatus {
      uniqueId
      vehicleNumber
      chassisNumber
    }
  }
`;

const GET_TOOL_ACTIVITY = gql`
  query GetToolActivityInfo($vin: String) {
    getToolActivityInfo(vin: $vin) {
      generatedDTC {
        count
        data {
          id
          ecu_name
          dealerAssignedId
          toolSerial
          dealerName
          dtcCodes
          createdOn
          imeiNo
          macAddress
          vinNo
          createdBy
          city
          state
          zone
          location
          userId
          toolSerialNo
          ecuName
          oemId
        }
      }
      vehicleFlashedInformation {
        count
        data {
          id
          toolSerialNumber
          dealerAssignedId
          dealerName
          ecu_name
          flashingStatus
          testStatus
          flashedDtc
          oldDatasetName
          newDatasetName
          macId
          imeiNo
          vinBeforeFlashing
          vinAfterFlashing
          ecuPartNoAfterFlashing
          esnBeforeFlashing
          esnAfterFlashing
          calidBeforeFlashing
          calidAfterFlashing
          cvnBeforeFlashing
          cvnAfterFlashing
          speedLimitBeforeFlashing
          speedLimitAfterFlashing
          tactTime
          flashedOn
          dealer
          employee
          toolSerialNo
          ecuName
          oemId
        }
      }
      liveParameters {
        count
        data {
          id
          livedata_download_url
          graph_download_url
          dealerAssignedId
          toolSerial_Number
          ecu_Name
          vinNumber
          createdOn
          csvFilePath
          graphFilePath
          ecuName
          toolSerialNumber
          oemId
        }
      }
      adjustmentParameters {
        count
        data {
          parameterId
          vinNo
          parameterName
          valueBeforeAdjustment
          valueAfterAdjustment
          createdOn
          oemId
          dealerAssignedId
          dealerName
          ecuName
        }
      }
    }
  }
`;

const GET_VEHICLE_SALES_DATA = gql`
query GetSalesData($vin: [String!]) {
  getSalesData(vin: $vin) {
    chasisNo
    engineNo
    applicationType
    modelCategory
    productName
    vehicleType
    gvw
    passengerCapacity
    wheelBase
    engineType
    brakeType
    emissionStandard
    dealerCode
    dealerContactName
    dealerContactNumber
    fuelType
    adblueTankCapacity
    yearOfManufacturing
  }
}`;


const ToolActivity = () => {

  const [tableData, setTableData] = useState<any>(null);
  const [allVehicleLiveStatus, setAllVehicleLiveStatus] = useState<LiveVehicle[]>([]);
  const [customerData, setCustomerData] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>(""); // State for the input field
  const [selectedVehicle, setSelectedVehicle] = useState<string | null>(null);
  const { isFirstLoad, markLoaded, resetFirstLoad } = useFirstLoad();


  const [getToolActivityInfo, { data: toolData, loading: toolLoading }] = useLazyQuery(GET_TOOL_ACTIVITY, {
    onCompleted: (data) => {
      setTableData(data.getToolActivityInfo);
    },
  });
  const [getAllVehicleLiveStatus] = useLazyQuery(GET_ALL_VEHICLE_LIVE_STATUS, {
    onCompleted: (data: { getAllVehicleLiveStatus: LiveVehicle[] }) => {
      setAllVehicleLiveStatus(data.getAllVehicleLiveStatus);
    },
  });

  useEffect(() => {
    getAllVehicleLiveStatus();
  }, [getAllVehicleLiveStatus]);

  const handleSubmit = () => {
    setTableData(null);
    if (inputValue.length <= 10) {
      enqueueSnackbar("Input must be more than 10 characters", { variant: "error" });
      return;
    }

    const matchedVehicle = allVehicleLiveStatus.find(
      (vehicle) => vehicle.chassisNumber.toLowerCase() === inputValue.toLowerCase()
    );

    setLoading(true);
    resetFirstLoad();

    const variables = {
      vin: matchedVehicle ? matchedVehicle.chassisNumber : inputValue, // Use matched VIN or input value
    };

    getToolActivityInfo({ variables });
  };


  return (
    <Box justifyContent="flex-start" sx={{ backgroundColor: "#F6F5F4", padding: 2 }}>
      <Grid container spacing={2} justifyContent="flex-start" sx={{ padding: 1 }}>
        <Grid item xs={4}>
          <Typography sx={{ fontSize: 32, fontWeight: "semi-bold", textAlign: "left" }}>
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Grid container justifyContent="flex-end" gap={1}>
            <Grid
              item
              xs={12}
              sm={4}
              md={3}
              lg={3}
              xl={3}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Autocomplete
                options={allVehicleLiveStatus.map((vehicle) => vehicle.chassisNumber)}
                freeSolo
                onInputChange={(event, value) => setInputValue(value)}
                onChange={(event, value) => setSelectedVehicle(value)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    label="Enter or Select VIN NUMBER"
                    variant="outlined"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        height: "56px", // Match button height
                      },
                    }}
                  />
                )}
                sx={{
                  width: "100%", // Ensures the Autocomplete matches the parent width
                  "& .MuiAutocomplete-popper": {
                    width: "100% !important", // Ensures the dropdown matches the text field
                  },
                }}
              />

            </Grid>
            <Grid
              item
              xs={12}
              sm={2}
              md={1}
              lg={1}
              xl={1}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Button
                fullWidth
                style={{
                  backgroundColor: theme.palette.black.main,
                  height: "56px", // Matches TextField height
                  borderRadius: "0.3rem",
                }}
                onClick={handleSubmit}
              >
                <ArrowForwardIcon style={{ color: theme.palette.white.main }} />
              </Button>
            </Grid>
          </Grid>

        </Grid>
      </Grid>
      <Grid item xs={12} md={12}>
        {toolLoading ? (
          <Skeleton variant="rectangular" width="100%" height={118} />
        ) : (
          <VehicleSearch data={tableData} vinNo={inputValue} />
        )}
      </Grid>
    </Box>
  );
};

export default ToolActivity;


